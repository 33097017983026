.testimonials-container {
  padding: 3rem 0px;
  /* background-color: #f9f9f9; */
  text-align: center;
  /* border: 1px solid red; */
}

h2 {
  margin-bottom: 1.5rem;
  color: #333;
  font-size: 2rem;
}

.testimonial-card {
  border-top-left-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  /* position: relative; */
  /* padding: 1.5rem; Reduced padding for a smaller card */
  background: #ffffff;
  /* margin: 1rem auto; Increased margin for bigger gap between cards */
  max-width: 300px; /* Set the max width for the card */
  display: flex;
  border: 1px solid skyblue;
}

.testimonial-avatar {
  width: 100px; /* Smaller avatar size */
  height: 100px;
  margin: 0 auto 1rem;
  border-radius: 50%;
}

.testimonial-text {
  font-style: italic;
  color: #555;
  /* margin-bottom: 0.5rem; Reduced bottom margin */
  font-size: 1rem; /* Smaller font size */
  font-family: 'Poppins';
}

.testimonial-name {
  font-weight: bold;
  margin: 0.5rem 0 0.2rem;
  color: #333;
}

.testimonial-position {
  color: #888;
  font-size: 0.9rem;
}
