.second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 2rem; */
    /* background-color: #eaf2f8;  */
    /* border: 1px solid yellow; */
    padding: 3rem 0px;
    /* margin-top: 30px; */
}

.section-title {
    margin-bottom: 2rem;
    font-size: 1.8rem; /* Default font size */
    font-weight: bold;
    color: #003366;
    text-transform: uppercase;
    /* Default font size for smaller screens */
}

/* Responsive font size for larger screens */
@media (min-width: 600px) {
    .section-title {
        font-size: 2.2rem; /* For medium screens (sm) */
    }
}

@media (min-width: 960px) {
    .section-title {
        font-size: 2.5rem; /* For large screens (md) */
    }
}

@media (min-width: 1280px) {
    .section-title {
        font-size: 2.8rem; /* For extra-large screens (lg) */
    }
}

.icon-container {
    width: 100%;
    max-width: 900px;
}

.icon-box {
    text-align: center; /* Center text under icons */
}

.icon {
    transition: transform 0.3s ease;
}

.icon:hover {
    transform: scale(1.1); /* Scale effect on hover */
}
